/* Carrusel */
.ant-carousel .slick-dots li button {
    min-width: 1rem; /* Ajusta el tamaño en vw según tus necesidades */
    max-width: 4rem;
    color: #0303eb;
    background-color: rgb(0, 0, 0);
}

.ant-carousel .slick-dots li.slick-active button {
    background-color: #414193; /* Cambia el color según tus preferencias */
}


/* Scroll */

.contenedor::-webkit-scrollbar {
    width: 7px;
    background: transparent;
}

.contenedor::-webkit-scrollbar-thumb {

    background: #414193b2;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #272777;
}


.contenedor::-webkit-scrollbar-track {

    background: #4141933d;

}
.contenedor::-webkit-scrollbar-track {

    background: transparent;

}
  

/* CandidateView */


.containerImg{
    width: 100%;
    height: 100%;

    
}

.containerRow{
    width:100vw ; 
    height:100vh ;
}

.buttonCarousel{
    width: 2vw;
    height: 90vh;
    border-width: 0.1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(65,65,147,1) 0%, rgb(39, 39, 114) 100%);
    color:white;
    
}
.buttonCarouselLeft{
    position: absolute;    
    left: 0;
    top: 50%;
}
.textButtonCarousel{
    width: 1vw;
    font-size: 2vh; 
}


.containerViewsInCarousel{
    margin-top: 1vh;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    border:1vh solid #e47b33;
    background-color: white;
    border-top-left-radius: 8vh;
    border-bottom-right-radius: 8vh;
    width: calc(var(--width-factor) * 85vw);
    height: calc(var(--height-factor) * 95vh);
}

:root {
    --width-factor:1;
    --height-factor:1;
}
  
.imagesInCarousel {
    width: 100%;
    height: calc(var(--height-factor) * 95vh);
    border-top-left-radius: 8vh;
    border-bottom-right-radius: 8vh;
    position: relative;
}
.imagesInCarouselPricing{
    width: calc(var(--width-factor) * 60vw);
    height: calc(var(--height-factor) *85vh);
    margin-top: -5vh;
    min-width: 600px;
    object-fit: contain;
    /* max-width: calc(var(--width-factor) * 600px);; */
}
.imagesInCarouselPricingBackground{
    filter: blur(20px);
    width: calc(var(--width-factor) * 55vw);
    height: calc(var(--height-factor) * 80vh);
    object-fit: contain;
    transition: filter 0.3s, width 0.3s; /* Agrega una transición para suavizar el cambio */

}
.imagesInCarouselPricingBackground:hover .imagesInCarouselPricing {
    filter: blur(25px);
    width: calc(var(--width-factor) * 60vw);
}

.buttonGeneral{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: auto;
    color: black;
    /* background-color: #6dbbe9; */
    background-color: transparent;
    border-color: transparent;
    font-size:3.5vh;
    border-radius: 1vh;
}

.buttonNextPag {
    position: fixed;
    right: 0.5vw;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    border: transparent;
    width: 9vh;
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: none;
}

.iconRowStyle{
     width: 9vh;
     height: auto;
}

.backgroundCar{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: linear-gradient(180deg, rgba(255,227,220,1) 0%, rgba(255,255,255,1) 100%);
}

.dotCarSelected {
    width: 2vh;
    height: 2vh;
    background:#414193;
    border:0.1vh solid #777777;
    border-radius: 50%;
    margin-right: 1vw;
}
.dotCar {
    width: 1.5vh;
    height: 1.5vh;
    background: #b6b6e4;
    border-radius: 50%;
    margin-right: 1vw;
}

.buttonNextPag::before {
    content: "";
    display: block;
    padding-top: 100%; /* Mantener una proporción cuadrada */
}
.buttonPrevPag {
    position: fixed;
    left: 0.5vw;
    z-index: 2;
    border: transparent;
    top: 50%;    
    transform: translateY(-50%);
    background-color: transparent;
    box-shadow: none;
    width: 9vh;
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
}

.buttonPrevPag::before {
    content: "";
    display: block;
    padding-top: 100%; /* Mantener una proporción cuadrada */
}

.buttonGeneral:hover{
  background-color: #59a2cc;
  font-size:3.6vh;
}

.container2View4Carousel{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.text1View4Carousel{
    font-size:5vh;
}
.text2View4Carousel{
    background-color: transparent;
    border: transparent;
    font-size:3vh;
    text-decoration: underline;
    color: #0000ff;
    cursor: pointer;
}
.text2View4Carousel:hover{
    color: #0202a7;
}

/* LOGIN */

.containerLoginForm{
    border-radius:3vh;
    width: 100%;
    height: 100%;
    /* border: 0.1vh solid #afafafdc; */
    background-color: white;
    /* box-shadow: 0.4vh 0.4vh 1vh #00000016; */
}


.textPricing4{
    font-size: 2vh;
    color: white;
    margin-top: 1vh;
}